import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoading: false,
	showReloadMsg: { isOpen: false, versionUpdate: null },
	snackbarAlerts: [
		//see structure in AlertMessage.js propTypes
	],
	allAircraftMakes: [],
	allAircraftModels: [],
	allBatteryMakes: [],
	allBatteryModels: [],
	accountAircraftModels: [],
	components: [],
	trafficPanelOpen: false,
	accountTagGroups: [],
	accountRoles: [],
	maintenanceSchedules: []
};

const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setGlobalIsLoading(state, action) {
			state.isLoading = action.payload;
		},
		setShowReloadMessage(state, action) {
			state.showReloadMsg = action.payload;
		},
		resetSnackbarAlerts(state, action) {
			state.snackbarAlerts = action.payload;
		},
		addSnackbarAlerts(state, action) {
			state.snackbarAlerts = [...state.snackbarAlerts, ...action.payload];
		},
		setAllAircraftMakes(state, action) {
			state.allAircraftMakes = action.payload;
		},
		setAllAircraftModels(state, action) {
			state.allAircraftModels = action.payload;
		},
		setAccountAircraftModels(state, action) {
			state.accountAircraftModels = action.payload;
		},
		setAccountAircraftMakes(state, action) {
			state.accountAircraftMakes = action;
		},
		setAllBatteryMakes(state, action) {
			state.allBatteryMakes = action.payload;
		},
		setAllBatteryModels(state, action) {
			state.allBatteryModels = action.payload;
		},
		setComponents(state, action) {
			state.components = action.payload;
		},
		setTrafficPanelOpen(state, action) {
			state.trafficPanelOpen = action.payload;
		},
		setAccountTagGroups(state, action) {
			state.accountTagGroups = action.payload;
		},
		setAccountRoles(state, action) {
			state.accountRoles = action.payload;
		},
		setMaintenanceSchedules(state, action) {
			state.maintenanceSchedules = action.payload;
		},
		resetGlobalSlice() {
			return initialState;
		}
	}
});

export const {
	setGlobalIsLoading,
	setShowReloadMessage,
	resetSnackbarAlerts,
	addSnackbarAlerts,
	resetGlobalSlice,
	setAllAircraftMakes,
	setAllAircraftModels,
	setAllBatteryMakes,
	setAllBatteryModels,
	setComponents,
	setTrafficPanelOpen,
	setAccountTagGroups,
	setAccountRoles,
	setMaintenanceSchedules,
	setAccountAircraftModels
} = globalSlice.actions;

export default globalSlice.reducer;

export const globalIsLoadingSelector = state => state.global.isLoading;
export const showReloadMsgSelector = state => state.global.showReloadMsg;
export const snackbarAlertsSelector = state => state.global.snackbarAlerts;
export const allAircraftMakesSelector = state => state.global.allAircraftMakes;
export const allAircraftModelsSelector = state => state.global.allAircraftModels;
export const allBatteryMakesSelector = state => state.global.allBatteryMakes;
export const allBatteryModelsSelector = state => state.global.allBatteryModels;
export const componentsSelector = state => state.global.components;
export const trafficPanelOpenSelector = state => state.global.trafficPanelOpen;
export const accountTagGroupsSelector = state => state.global.accountTagGroups;
export const accountRolesSelector = state => state.global.accountRoles;
export const maintenanceSchedulesSelector = state => state.global.maintenanceSchedules;
export const accountAircraftModelsSelector = state => state.global.accountAircraftModels;
